<template>
    <span :class="`nuxt-icon-container fa-${name} ${classes}`">
        {{ placement === 'right' && (isDevice || !emptyMobileTitle) ? title : '' }}
        <nuxt-icon
            :name="`fontawesome/${name}`"
            :class="[
                placement === 'left' && title !== null && (isDevice || !emptyMobileTitle) ? 'mr-1' : '',
                placement === 'right' && title !== null && (isDevice || !emptyMobileTitle) ? 'ml-1' : '',
            ]"
        />
        {{ placement === 'left' && (isDevice || !emptyMobileTitle) ? title : '' }}
    </span>
</template>

<script>

import { defineComponent, computed, toRefs } from 'vue';
import useResponsible from '@/hooks/responsible';

export default defineComponent({
    name: 'IconFontAwesome',
    props: {
        name: {
            type: [String],
            default: () => 'times',
        },
        classes: {
            type: [String],
            default: () => '',
        },
        title: {
            type: [String],
            default: () => null,
        },
        placement: {
            type: [String],
            default: () => 'left',
        },
        emptyMobileTitle: {
            type: [Boolean],
            default: () => false,
        },
        checkDevice: {
            type: [String],
            default: () => 'desktop', // desktop, tablet
        }
    },
    setup(props) {
        const {
            checkDevice,
        } = toRefs(props);

        const responsible = useResponsible();

        const isDevice = computed(() => checkDevice.value === 'tablet' ? responsible.desktopTablet() : responsible.desktop());
        return {
            isDevice,
        };
    },
});
</script>
