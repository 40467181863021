// import { useMatches } from 'vue-responsiveness';
import { useDevice } from '#imports';

// https://nuxt.com/modules/device#change-a-layout-dynamically
export default function useResponsible() {
    const { isMobile, isDesktop, isTablet, isDesktopOrTablet } = useDevice();
    // const matches = useMatches();
    const mobile = () => {
        return isMobile;
    };
    const tablet = () => {
        return isTablet;
    };
    const desktop = () => {
        return isDesktop;
    };
    const desktopTablet = () => {
        return isDesktopOrTablet;
    };
    const type = () => {
        if (desktop()) {
            return 'desktop';
        }
        if (mobile()) {
            return 'mobile';
        }
        if (tablet()) {
            return 'tablet';
        }
        return 'non-device';
    };
    return {
        type,
        tablet,
        mobile,
        desktop,
        desktopTablet,
    };
}
